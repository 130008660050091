import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTitle from '../hooks/title.hook'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Navbar from '../components/Navbar.js'
import AppTheme from '../shared-theme/AppTheme'

export const GuidePage = (props) => {
   useTitle('Инструкция - InterBoard')
   return (
      <AppTheme {...props}>
         <Navbar />
         <CssBaseline />
         <Box sx={{ width: '100%', maxWidth: 1000 }}>
            <Container component="main" maxWidth="lg">
               <Typography variant="h2" gutterBottom>
                  Инструкция
               </Typography>
               <Typography variant="h3" gutterBottom>
                  Управление
               </Typography>
               <Typography variant="h4" gutterBottom>
                  Менять масштаб на колёсико мыши. Перемещаться можно перетаскиванием зажатой ПКМ или СКМ. Можно стирать последнее написанное двойным кликом ПКМ. Можно добавлять
                  текст на двойной клик ЛКМ. Можно возращаться к последнему написаному с помощью функции в интерфейсе "toLastDraw". Можно перемещать всех пользователей на доске к
                  вам с помощью функции в интерфейсе "EveryoneToMe". Можно быстро переключаться между инструментами с помощью клавиш 1-4. Также можно вставлять картинки при помощи
                  вставки из буффера обмена. Можно отматывать действия назад и вперёд с помощью стрелочек. В левом нижнем углу есть кнопка настройки для доски. Чтобы поделиться
                  ссылкой на доску достаточно просто скопировать ссылку, на которой вы сейчас вида https://hayetirjied.beget.app/board/66f425c113831ea328b3b2c0/ , регистрироваться
                  ученику на доске НЕ нужно.
               </Typography>
               <Typography variant="h3" gutterBottom>
                  1. Инструмент стрелочка
               </Typography>
               <Typography variant="h4" gutterBottom>
                  Позволяет выделять объекты для их перемещения при помощи перетаскивания мышки, для изменения размера при помощи колёсика мышки(мышка должна быть зажата) или с
                  помощью серых кружков, для их удаления при помощи клавиши DELETE или BACKSPACE
               </Typography>
               <Typography variant="h3" gutterBottom>
                  2. Инструмент карандаш
               </Typography>
               <Typography variant="h4" gutterBottom>
                  Позволяет рисовать. В интерфейсе можно менять цвет, размер и прозрачность карандаша. Также можно рисовать прямые линии, зажимая Shift.
               </Typography>
               <Typography variant="h3" gutterBottom>
                  3. Инструмент ластик
               </Typography>
               <Typography variant="h4" gutterBottom>
                  Позволяет стирать нарисованные линии.
               </Typography>
               <Typography variant="h3" gutterBottom>
                  4. Инструмент текст
               </Typography>
               <Typography variant="h4" gutterBottom>
                  Позволяет вставлять текстовые элементы и редактировать их, если они выделены. Также их можно редактировать, если они выделены стрелочкой.
               </Typography>
               {/* <Typography variant="h3" gutterBottom>
       На этой странице будет инструкция по использованию сайта.
       По использованию менеджера досок, самой доски и её функций  с гифками.
    </Typography> */}
            </Container>
         </Box>
      </AppTheme>
   )
}
