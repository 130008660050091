import React, { createContext, useContext, useState, useCallback } from 'react'
import { Alert, Snackbar } from '@mui/material'

// Создаем контекст
const AlertContext = createContext()

// Поставщик вашего контекста
export const AlertProvider = ({ children }) => {
   const [alert, setAlert] = useState({ open: false, message: '', severity: 'info' })

   // Функция для вызова Alert
   const showAlert = useCallback((message, severity = 'info') => {
      setAlert({ open: true, message, severity })
   }, [])

   // Функция для закрытия Alert
   const handleClose = useCallback(() => {
      setAlert((prev) => ({ ...prev, open: false }))
   }, [])

   return (
      <AlertContext.Provider value={showAlert}>
         {children}
         <Snackbar open={alert.open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity={alert.severity} variant="filled">
               {alert.message}
            </Alert>
         </Snackbar>
      </AlertContext.Provider>
   )
}

// Хук для использования контекста
export const useMessage = () => useContext(AlertContext)
