import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function FAQ() {
   const [expanded, setExpanded] = React.useState([])

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? [...expanded, panel] : expanded.filter((item) => item !== panel))
   }

   return (
      <Container
         id="faq"
         sx={{
            pt: { xs: 4, sm: 12 },
            pb: { xs: 8, sm: 16 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
         }}>
         <Typography
            component="h2"
            variant="h4"
            sx={{
               color: 'text.primary',
               width: { sm: '100%', md: '60%' },
               textAlign: { sm: 'left', md: 'center' },
            }}>
            Часто задаваемые вопросы
         </Typography>
         <Box sx={{ width: '100%' }}>
            <Accordion expanded={expanded.includes('panel2')} onChange={handleChange('panel2')}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                  <Typography component="span" variant="subtitle2">
                     Для чего конкретно подойдёт этот сервис?
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                     Наш сервис предназначен прежде всего для онлайн-занятий с использованием онлайн-доски. Вы совместно с учениками сможете рисовать, гибко манипулировать
                     объектами и создавать их для большей наглядости изучаемого материала.
                  </Typography>
               </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded.includes('panel3')} onChange={handleChange('panel3')}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
                  <Typography component="span" variant="subtitle2">
                     Почему для онлайн-занятий стоит выбрать именно ваш сервис?
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                     Наш сервис ориентирован на максимально дружелюбный интерфейс, в котором сможет разобраться даже ученик начальной школы, и на глубоко продуманный широкий
                     функционал, который сделает ваш опыт работы с доской предельно комфортным.
                  </Typography>
               </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded.includes('panel1')} onChange={handleChange('panel1')}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                  <Typography component="span" variant="subtitle2">
                     Почему сервис полностью бесплатный?
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                     В данный момент ведётся активная разработка и тестирование сервиса, поэтому мы нуждаемся в обратной связи от как можно большего количечества пользователей. В
                     ближайшем будущем помимо бесплатного тарифа появятся и платные тарифы, которые позволят ещё более удобно и эффективно работать с онлайн-досками.
                  </Typography>
               </AccordionDetails>
            </Accordion>
         </Box>
      </Container>
   )
}
