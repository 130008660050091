import React, { useContext, useState, useEffect } from 'react'
import { useMessage } from '../hooks/AlertContext.js'
import { AuthContext } from '../context/AuthContext'
import $api from '../http'
import useTitle from '../hooks/title.hook'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import AppTheme from '../shared-theme/AppTheme'
import Cookies from 'js-cookie'
import Navbar from '../components/Navbar.js'

export const SettingsPage = (props) => {
   useTitle('Настройки - InterBoard')
   const message = useMessage()
   const auth = useContext(AuthContext) //Контекст
   const handleSubmit = (event) => {
      event.preventDefault()
   }
   // useEffect(() => {
   //    setTheme(Cookies.get('boardTheme'))
   // }, [])
   const activateMyProfile = async () => {
      try {
         const activateMyProfileData = (await $api.post('/api/auth/activateMyProfile', { userId: auth.userId })).data
         message(activateMyProfileData.message)
      } catch (error) {
         message(error.response.data)
      }
   }
   return (
      <AppTheme {...props}>
         <Navbar />
         <Box sx={{ width: '100%', maxWidth: 2000 }}>
            <Container component="main" maxWidth="xs">
               <CssBaseline />
               <Box
                  onSubmit={handleSubmit}
                  sx={{
                     marginTop: 8,
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                  }}>
                  <Typography component="h1" variant="h2">
                     Настройки
                  </Typography>
                  <Button type="submit" fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }} onClick={activateMyProfile}>
                     Выслать письмо на мою электронную почту для её подтверждения
                  </Button>
               </Box>
            </Container>
         </Box>
      </AppTheme>
   )
}
