import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded'
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded'

const items = [
   {
      icon: <SettingsSuggestRoundedIcon />,
      title: 'Отличная производительность',
      description: 'Наша доска отлично работает даже на слабых системах: ноутбуках, планшетах, телефонах.',
   },
   //  {
   //     icon: <ConstructionRoundedIcon />,
   //     title: 'Стабильность',
   //     description: 'Experience unmatched durability that goes above and beyond with lasting investment.',
   //  },
   {
      icon: <ThumbUpAltRoundedIcon />,
      title: 'Дружелюбный интерфейс',
      description: 'Мы поддерживаем максимально простой интерфейс, чтобы в нём смог разобраться кто угодно.',
   },
   {
      icon: <AutoFixHighRoundedIcon />,
      title: 'Широкий функционал',
      description: 'Мы постоянно совершенствуем функциональность и гибкость управления доской.',
   },
   {
      icon: <SupportAgentRoundedIcon />,
      title: 'Оперативная служба поддержки',
      description: 'Вы всегда можете обратиться в нашу службу поддержки за помощью или с вопросом по поводу использования сервиса.',
   },
   //  {
   //     icon: <QueryStatsRoundedIcon />,
   //     title: 'Precision in every detail',
   //     description: 'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
   //  },
]

export default function Highlights() {
   return (
      <Box
         id="highlights"
         sx={{
            pt: { xs: 4, sm: 12 },
            pb: { xs: 8, sm: 16 },
            color: 'white',
            // bgcolor: 'grey.900',
         }}>
         <Container
            sx={{
               position: 'relative',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               gap: { xs: 3, sm: 6 },
            }}>
            <Box
               sx={{
                  width: { sm: '100%', md: '60%' },
                  textAlign: { sm: 'left', md: 'center' },
               }}>
               <Typography component="h2" variant="h4" gutterBottom>
                  Наши преимущества
               </Typography>
               {/* <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer support and
            precision in every detail.
          </Typography> */}
            </Box>
            <Grid container spacing={2}>
               {items.map((item, index) => (
                  <Grid size={{ xs: 12, sm: 6, md: 3 }} key={index}>
                     <Stack
                        direction="column"
                        component={Card}
                        spacing={1}
                        useFlexGap
                        sx={{
                           color: 'inherit',
                           p: 3,
                           height: '100%',
                           borderColor: 'hsla(220, 25%, 25%, 0.3)',
                           backgroundColor: 'brand.500',
                        }}>
                        <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                        <div>
                           <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                              {item.title}
                           </Typography>
                           <Typography variant="body2" sx={{ color: 'grey.300' }}>
                              {item.description}
                           </Typography>
                        </div>
                     </Stack>
                  </Grid>
               ))}
            </Grid>
         </Container>
      </Box>
   )
}
