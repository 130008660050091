import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import InputLabel from '@mui/material/InputLabel'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ImageCard from './ImageCard'
// import visuallyHidden from '@mui/utils/visuallyHidden';
import { styled } from '@mui/material/styles'

const StyledBox = styled('div')(({ theme }) => ({
   alignSelf: 'center',
   width: '100%',
   height: '100%',
   // height: 400,
   marginTop: theme.spacing(8),
   borderRadius: (theme.vars || theme).shape.borderRadius,
   outline: '6px solid',
   outlineColor: 'hsla(220, 25%, 80%, 0.2)',
   border: '1px solid',
   borderColor: (theme.vars || theme).palette.grey[200],
   boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
   backgroundImage: `url(https://i.postimg.cc/26BGjw6X/g14-1024-2.png)`,
   // backgroundImage: `url(${process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'}/static/screenshots/material-ui/getting-started/templates/dashboard.jpg)`,
   backgroundSize: 'cover',
   [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
      height: 700,
   },
   ...theme.applyStyles('dark', {
      boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
      // backgroundImage: `url(${process.env.TEMPLATE_IMAGE_URL || 'https://mui.com'}/static/screenshots/material-ui/getting-started/templates/dashboard-dark.jpg)`,
      outlineColor: 'hsla(220, 20%, 42%, 0.1)',
      borderColor: (theme.vars || theme).palette.grey[700],
   }),
}))

export default function Hero() {
   return (
      <Box
         id="hero"
         sx={(theme) => ({
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
            ...theme.applyStyles('dark', {
               backgroundImage: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
            }),
         })}>
         <Container
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               pt: { xs: 14, sm: 20 },
               pb: { xs: 8, sm: 12 },
            }}>
            <Stack spacing={2} useFlexGap sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}>
               <ImageCard />
               <Typography
                  variant="h1"
                  sx={{
                     display: 'flex',
                     flexDirection: { xs: 'column', sm: 'row' },
                     alignItems: 'center',
                     fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                  }}>
                  Онлайн-доска от&nbsp;
                  <Typography
                     component="span"
                     variant="h1"
                     sx={(theme) => ({
                        fontSize: 'inherit',
                        color: 'primary.main',
                        ...theme.applyStyles('dark', {
                           color: 'primary.light',
                        }),
                     })}>
                     учителей
                  </Typography>
               </Typography>
               <Typography
                  variant="h1"
                  align="right"
                  sx={{
                     // display: 'flex',
                     textAlign: 'right',
                     // flexDirection: { xs: 'column', sm: 'row' },
                     fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                  }}>
                  для&nbsp;
                  <Typography
                     component="span"
                     variant="h1"
                     sx={(theme) => ({
                        fontSize: 'inherit',
                        color: 'primary.main',
                        ...theme.applyStyles('dark', {
                           color: 'primary.light',
                        }),
                     })}>
                     учителей
                  </Typography>
               </Typography>
               {/* <Typography
                  sx={{
                     textAlign: 'center',
                     color: 'text.secondary',
                     width: { sm: '100%', md: '80%' },
                  }}>
                  Explore our cutting-edge dashboard, delivering high-quality solutions tailored to your needs. Elevate your experience with top-tier features and services.
               </Typography> */}
               {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} useFlexGap sx={{ pt: 2, width: { xs: '100%', sm: '350px' } }}> */}
               {/* <InputLabel htmlFor="email-hero" sx={visuallyHidden}> */}
               {/* <InputLabel htmlFor="email-hero">Email</InputLabel> */}
               {/* <TextField
                     id="email-hero"
                     hiddenLabel
                     size="small"
                     variant="outlined"
                     aria-label="Enter your email address"
                     placeholder="Your email address"
                     fullWidth
                     slotProps={{
                        htmlInput: {
                           autoComplete: 'off',
                           'aria-label': 'Enter your email address',
                        },
                     }}
                  /> */}
               <Button variant="contained" color="info" size="large" sx={{ mt: 2, minWidth: 'fit-content' }} href="/registration">
                  Попробовать прямо сейчас
               </Button>
               {/* </Stack> */}
            </Stack>
            {/* <StyledBox id="image" /> */}
         </Container>
      </Box>
   )
}
