import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import AppTheme from '../shared-theme/AppTheme'
import Link from '@mui/material/Link'
import ColorModeIconDropdown from '../shared-theme/ColorModeIconDropdown'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { useMessage } from '../hooks/AlertContext.js'
import $api from '../http'
import Cookies from 'js-cookie'
import myImage from '../logoNavbar3.png'
const pages = [
   { name: 'Как пользоваться?', link: '/guide' },
   // { name: 'Новости сайта', link: '/news' },
   // { name: 'Управление подпиской', link: '/profile' },
]

function Navbar(props) {
   const message = useMessage()
   const navigate = useNavigate()
   const auth = useContext(AuthContext)
   const logoutHandler = async (event) => {
      event.preventDefault()
      try {
         await $api.post('/api/auth/logout')
         auth.logout()
         navigate('/')
         message('Выход из системы')
      } catch (error) {
         message(error.response.data)
      }
   }
   const [anchorElNav, setAnchorElNav] = useState(null)
   const [anchorElUser, setAnchorElUser] = useState(null)
   const [username, setUsername] = useState('')
   useEffect(() => {
      setUsername(Cookies.get('username'))
   }, [])
   const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget)
   }
   const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget)
   }

   const handleCloseNavMenu = () => {
      setAnchorElNav(null)
   }

   const handleCloseUserMenu = () => {
      setAnchorElUser(null)
   }

   return (
      <AppBar position="static">
         <AppTheme {...props}>
            <Container maxWidth="xl">
               <Toolbar disableGutters sx={{ gap: 2 }}>
                  <a href="/main">
                     <img src={myImage} alt="Описание изображения" style={{ height: '50px' }} />
                  </a>
                  <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                     <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                        <MenuIcon />
                     </IconButton>
                     <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                           display: { xs: 'block', md: 'none' },
                        }}>
                        {pages.map((page) => (
                           <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                              <Typography textAlign="center">{page.name}</Typography>
                           </MenuItem>
                        ))}
                     </Menu>
                  </Box>
                  <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                  <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                     {pages.map((page) => (
                        <Button size="large" key={page.name} onClick={handleCloseNavMenu} href={page.link} sx={{ my: 2, color: 'white', display: 'block' }}>
                           {page.name}
                        </Button>
                     ))}
                  </Box>
                  <Typography
                     variant="h6"
                     noWrap
                     component="a"
                     sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.1rem',
                        color: 'inherit',
                        textDecoration: 'none',
                     }}>
                     {username}
                  </Typography>
                  {/* Меню настроек  */}
                  <Box sx={{ flexGrow: 0 }}>
                     <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                           <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                        </IconButton>
                     </Tooltip>
                     <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}>
                        {/* <Link href="/profile">
                        <MenuItem onClick={handleCloseUserMenu}>
                           <Typography textAlign="center">Профиль</Typography>
                        </MenuItem>
                     </Link> */}
                        <Link href="/settings">
                           <MenuItem onClick={handleCloseUserMenu}>
                              <Typography textAlign="center">Настройки</Typography>
                           </MenuItem>
                        </Link>
                        <MenuItem onClick={logoutHandler} href="/profile">
                           <Typography textAlign="center" color="error">
                              Выйти
                           </Typography>
                        </MenuItem>
                     </Menu>
                  </Box>
                  <ColorModeIconDropdown size="medium" />
               </Toolbar>
            </Container>
         </AppTheme>
      </AppBar>
   )
}
export default Navbar
