import React, { useContext, useEffect, useState } from 'react'
import { useMessage } from '../hooks/AlertContext.js'
import { AuthContext } from '../context/AuthContext'
import $api from '../http'
import useTitle from '../hooks/title.hook'
import Cookies from 'js-cookie'
import ImageCard from '../components/ImageCard2.js'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import AppTheme from '../shared-theme/AppTheme'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function Copyright(props) {
   return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
         {'Copyright © '}
         <Link color="inherit" href="#">
            InterBoard
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   )
}

export const LoginPage = (props) => {
   useTitle('Авторизация - InterBoard')
   const auth = useContext(AuthContext)
   const message = useMessage()
   const [form, setForm] = useState({ email: '', password: '' })
   const changeHandler = (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
   }
   const loginHandler = async () => {
      try {
         const data = (await $api.post('/api/auth/login', { ...form })).data
         message(data.message)
         if (data.userId) auth.login(Cookies.get('accessToken'), data.userId, data.name, data.prefBoardTheme)
      } catch (error) {
         message(error.response.data)
      }
   }
   const handleSubmit = (event) => {
      event.preventDefault()
   }
   const [showPassword, setShowPassword] = React.useState(false)
   const handleClickShowPassword = () => setShowPassword((show) => !show)

   const handleMouseDownPassword = (event) => {
      event.preventDefault()
   }

   const handleMouseUpPassword = (event) => {
      event.preventDefault()
   }

   return (
      <AppTheme {...props}>
         <ImageCard />
         <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}>
               <Typography component="h1" variant="h4">
                  Вход
               </Typography>
               <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                     InputProps={{
                        style: {
                           paddingLeft: 10,
                           paddingRight: 10,
                        },
                     }}
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email адрес"
                     name="email"
                     autoComplete="email"
                     autoFocus
                     onChange={changeHandler}
                  />
                  <FormControl variant="outlined" fullWidth required>
                     <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                     <OutlinedInput
                        sx={{
                           paddingLeft: '10px', // Задайте нужное значение отступа
                        }}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        onChange={changeHandler}
                        endAdornment={
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label={showPassword ? 'hide the password' : 'display the password'}
                                 onClick={handleClickShowPassword}
                                 onMouseDown={handleMouseDownPassword}
                                 onMouseUp={handleMouseUpPassword}
                                 edge="end">
                                 {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                           </InputAdornment>
                        }
                        label="пароль"
                     />
                  </FormControl>
                  <Box sx={{ textAlign: 'right', mt: 1 }}>
                     <Link href="/forgotpassword" variant="body2">
                        {'Забыли пароль?'}
                     </Link>
                  </Box>
                  {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Запомнить меня" /> */}
                  <Button type="submit" fullWidth variant="contained" color="info" sx={{ mt: 2, mb: 2 }} onClick={loginHandler}>
                     Войти
                  </Button>
                  <Grid container>
                     {/* <Grid item xs>
                        <Link href="#" variant="body2">
                           Забыли пароль?
                        </Link>
                     </Grid> */}
                     <Grid item>
                        <Link href="/registration" variant="body2">
                           {'Нет аккаунта? Зарегистрируйтесь'}
                        </Link>
                     </Grid>
                  </Grid>
               </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
         </Container>
      </AppTheme>
   )
}
