import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import { Suspense } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useTitle from '../hooks/title.hook'
import CssBaseline from '@mui/material/CssBaseline'
import { AuthContext } from '../context/AuthContext'
import BoardCard from '../components/BoardCard.js'
import CreateNewBoard from '../components/CreateNewBoard.js'
import $api from '../http/index.js'
import Container from '@mui/material/Container'
import AppTheme from '../shared-theme/AppTheme'
import Navbar from '../components/Navbar.js'
import { useMessage } from '../hooks/AlertContext.js'
export const MainPage = (props) => {
   useTitle('Главная страница - InterBoard')
   const message = useMessage()
   const auth = useContext(AuthContext) //Контекст

   const [listOfBoards, setListOfBoards] = useState([]) //Это для отображения списка досок
   const [open, setOpen] = useState(false) //Открытие и закрытие диалога
   const [name, setName] = useState('') //Для считывания названия доски из диалогового окна
   const [description, setDescription] = useState('') //Для считывания названия доски из диалогового окна
   const [currentTime, setCurrentTime] = useState(new Date())
   const handleChange = (e) => setName(e.target.value)
   const handleChangeForDescription = (e) => setDescription(e.target.value)

   useEffect(() => {
      //Для начального рендера списка досок
      const asyncFn = async () => {
         try {
            const data = (
               await $api.post('/api/manager/render', {
                  id: auth.userId,
               })
            ).data
            setListOfBoards(data.myboards)
         } catch (error) {
            message(error.response.data)
         }
      }
      asyncFn()
   }, [auth.userId])
   useEffect(() => {
      const timerID = setInterval(() => setCurrentTime(new Date()), 1000)
      return () => clearInterval(timerID) // Очистка таймера при размонтировании компонента
   }, [])

   const handleClickOpen = () => {
      setOpen(true)
   }
   const handleClose = () => {
      setOpen(false)
   }

   const handleCreate = async () => {
      //Создание доски
      let boardName = name
      if (!boardName) boardName = 'Доска от ' + currentTime.toLocaleDateString() + ', ' + currentTime.toLocaleTimeString()
      if (listOfBoards.length >= 30) {
         message('Достигнуто максимальное количество досок!')
         return
      }
      let isUniq = true
      listOfBoards.forEach((el) => {
         //Проверка на уникальность названия новой доски
         if (el.name === boardName) isUniq = false
      })
      if (!isUniq) {
         message('Доска с таким именем уже существует!')
         return
      }
      if (description.length > 500) {
         message('Описание слишком длинное')
         return
      }
      if (boardName.length > 50) {
         message('Название слишком длинное')
         return
      }
      try {
         const data = (
            await $api.post('/api/manager/create', {
               id: auth.userId,
               name: boardName,
               description: description,
            })
         ).data
         message(data.message)
         if (data.myboards) {
            setOpen(false)
            setListOfBoards(data.myboards)
            setName('')
            setDescription('')
         }
      } catch (error) {
         message(error.response.data)
      }
   }

   return (
      <AppTheme {...props}>
         <Navbar />
         <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Box maxWidth="lg" sx={{ flexGrow: 5 }}>
               {/* Начало диалога по созданию доски */}
               <Dialog open={open} onClose={handleClose}>
                  <DialogTitle
                     sx={{
                        fontWeight: 'bold',
                        fontSize: '24px',
                        textAlign: 'center',
                     }}>
                     Создание доски
                  </DialogTitle>
                  <DialogContent>
                     {/* <DialogContentText>Для создания доски необходимо указать её название.</DialogContentText> */}
                     <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Название доски (необязательно)"
                        type="string"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                           style: { fontSize: '16px', fontWeight: 'bold' }, // Установите желаемый размер шрифта
                        }}
                        onChange={handleChange}
                     />
                     <TextField
                        margin="dense"
                        // multiline
                        minRows={3}
                        id="description"
                        label="Описание (необязательно)"
                        type="string"
                        fullWidth
                        variant="outlined"
                        onChange={handleChangeForDescription}
                     />
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={handleClose}>Отмена</Button>
                     <Button onClick={handleCreate}>Создать</Button>
                  </DialogActions>
               </Dialog>
               {/* Конец диалога по созданию доски */}
               {/* <Typography variant="h2" gutterBottom>
                  Мои доски
               </Typography> */}
               <Suspense fallback={<h2>Загружаем всё необходимое....</h2>}>
                  <Grid
                     container
                     spacing={{ xs: 2, md: 3 }}
                     columns={{ xs: 4, sm: 8, md: 12 }}
                     sx={{
                        display: 'flex',
                        justifyContent: 'center', // Выравнивание по горизонтали
                        marginTop: 6,
                     }}>
                     <CreateNewBoard onClick={handleClickOpen} />
                     {listOfBoards
                        .slice(0)
                        .reverse()
                        .map((board, index) => (
                           <Grid item xs={2} sm={4} md={4} key={index}>
                              <BoardCard name={board.name} boardId={board.boardId} description={board.description} setList={setListOfBoards} />
                           </Grid>
                        ))}
                  </Grid>
               </Suspense>
            </Box>
         </Container>
      </AppTheme>
   )
}
