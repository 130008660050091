import React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
function ImageCard() {
   return (
      <Card sx={{ boxShadow: 'none', border: 'none' }}>
         <CardMedia
            component="img"
            height="auto" // укажите высоту или используйте "auto" для автоматического масштабирования
            image="https://i.postimg.cc/26BGjw6X/g14-1024-2.png"
            alt="Description of the image"
         />
      </Card>
   )
}

export default ImageCard
