import React, { useState, useContext } from 'react'

import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import { AuthContext } from '../context/AuthContext'
import { useMessage } from '../hooks/AlertContext.js'
import $api from '../http'

import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

export default function LongMenu(props) {
   const auth = useContext(AuthContext) //Контекст
   const message = useMessage()
   const [anchorEl, setAnchorEl] = React.useState(null)
   const [name, setName] = useState('') //Для считывания названия доски из диалогового окна
   const [description, setDescription] = useState('') //Для считывания названия доски из диалогового окна
   const open = Boolean(anchorEl)
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
   }
   const handleClose = () => {
      setAnchorEl(null)
   }

   const openRenameWindow = () => {
      setAnchorEl(null)
      setOpenRename(true)
      setName(props.name)
      setDescription(props.description)
   }
   const handleRename = async () => {
      //Переименование доски
      try {
         setAnchorEl(null)
         setOpenRename(false)
         const data = (
            await $api.post('/api/manager/renameboard', {
               id: auth.userId,
               boardId: props.boardId,
               newname: name,
               newdescription: description,
            })
         ).data
         message(data.message)
         props.setList(data.myboards)
      } catch (error) {
         message(error.response.data)
      }
   }
   const handleClose2 = () => {
      setOpenRename(false)
   }
   const [openRename, setOpenRename] = useState(false) //Открытие и закрытие диалога
   const handleChange = (e) => setName(e.target.value)
   const handleChangeForDescription = (e) => setDescription(e.target.value)
   const useDelete = async () => {
      //Удаление доски
      setAnchorEl(null)
      const data = (
         await $api.post('/api/manager/delete', {
            id: auth.userId,
            name: props.name,
         })
      ).data
      message(data.message)
      props.setList(data.myboards)
   }

   return (
      <div>
         <Dialog open={openRename} onClose={handleClose2}>
            <DialogTitle
               sx={{
                  fontWeight: 'bold',
                  fontSize: '24px',
                  textAlign: 'center',
               }}>
               Переименование
            </DialogTitle>
            <DialogContent>
               <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Название доски (необязательно)"
                  type="string"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                     style: { fontSize: '16px', fontWeight: 'bold' }, // Установите желаемый размер шрифта
                  }}
                  onChange={handleChange}
                  defaultValue={props.name}
               />
               <TextField
                  margin="dense"
                  // multiline
                  minRows={3}
                  id="description"
                  label="Описание (необязательно)"
                  type="string"
                  fullWidth
                  variant="outlined"
                  onChange={handleChangeForDescription}
                  defaultValue={props.description}
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose2}>Отмена</Button>
               <Button onClick={handleRename}>Переименовать</Button>
            </DialogActions>
         </Dialog>
         <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon />
         </IconButton>
         <Menu
            id="long-menu"
            slotProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <MenuItem onClick={openRenameWindow}>
               <Typography textAlign="center">Переименовать</Typography>
            </MenuItem>
            <MenuItem onClick={useDelete} color="error">
               <Typography textAlign="center" color="error">
                  Удалить
               </Typography>
            </MenuItem>
         </Menu>
      </div>
   )
}
