import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { SettingsPage } from './pages/SettingsPage'
import { LoginPage } from './pages/LoginPage'
import { ForgotPasswordPage } from './pages/ForgotPasswordPage'
import { PasswordChangingPage } from './pages/PasswordChangingPage'
import { RegistrationPage } from './pages/RegistrationPage'
import { GuidePage } from './pages/GuidePage'
import MarketingPage from './pages/MarketingPage'
import { NewsPage } from './pages/NewsPage'
import { MainPage } from './pages/MainPage'
import { ProfilePage } from './pages/ProfilePage'

export const useRoutes = (isAuthenticated) => {
   if (isAuthenticated) {
      return (
         <Routes>
            <Route path="/" element={<MarketingPage disableCustomTheme={false} />} />
            <Route path="*" element={<MainPage />} />
            <Route path="/main" exact element={<MainPage />} />
            <Route path="/settings" exact element={<SettingsPage />} />
            <Route path="/guide" element={<GuidePage />} />
            {/* <Route path="/news" element={<NewsPage />} /> */}
            {/* <Route path="/profile" element={<ProfilePage />} /> */}
            <Route path="/board/:id" element={<Board />} />
         </Routes>
      )
   }
   return (
      <Routes>
         <Route path="*" element={<MarketingPage disableCustomTheme={false} />} />
         <Route path="/" exact element={<MarketingPage disableCustomTheme={false} />} />
         <Route path="/login" exact element={<LoginPage />} />
         <Route path="/registration" exact element={<RegistrationPage />} />
         <Route path="/forgotpassword" exact element={<ForgotPasswordPage />} />
         <Route path="/passwordchanging/:token" exact element={<PasswordChangingPage />} />
      </Routes>
   )
}
const Board = () => {
   const { id } = useParams()
   //Здесь нужно поменять на interboard.online
   window.location.href = process.env.REACT_APP_API_URL + '/board/' + id
   return null
}
