import React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'

function Sitemark() {
   return (
      <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}>
         <CardMedia
            component="img"
            height="auto" // укажите высоту или используйте "auto" для автоматического масштабирования
            image="https://i.postimg.cc/QdFkXmHG/2.png"
            alt="Description of the image"
            style={{ width: '16px' }}
         />
         {/* Добавьте другие элементы Card, такие как CardContent, если нужно */}
      </Card>
   )
}

export default Sitemark
