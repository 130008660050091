import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import BoardMenu from './BoardMenu.js'
import Grid from '@mui/material/Grid2'
import myImage from '../back2.png'
import CssBaseline from '@mui/material/CssBaseline'
var link = '/board' //Ссылки вписаны вручную временно

export default function BoardCard(props) {
   return (
      <Card sx={{ maxWidth: 345 }}>
         <CssBaseline />
         <CardActionArea href={'/board/' + props.boardId}>
            <CardMedia component="img" image={myImage} alt="Картинка для данной доски" />
         </CardActionArea>
         <CardContent>
            <Grid
               container
               sx={{
                  marginTop: 2,
               }}>
               <Grid size={11}>
                  {/* <CardActionArea href={link}> */}
                  <CardActionArea href={'/board/' + props.boardId}>
                     <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                           // whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           marginLeft: 2,
                        }}>
                        {props.name}
                     </Typography>
                  </CardActionArea>
               </Grid>
               <Grid size={1}>
                  <BoardMenu name={props.name} description={props.description} setList={props.setList} boardId={props.boardId} />
               </Grid>
            </Grid>
            <Typography
               variant="body2"
               color="text.secondary"
               // noWrap
               // multiline
               sx={{
                  // whiteSpace: 'nowrap',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis',
                  marginLeft: 2,
               }}>
               {props.description}
            </Typography>
         </CardContent>
      </Card>
   )
}
