import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import BoardMenu from './BoardMenu.js'
import Grid from '@mui/material/Grid2'
import myImage from '../plus3.png'
import CssBaseline from '@mui/material/CssBaseline'

export default function CreateNewBoard(props) {
   return (
      <Card sx={{ maxWidth: 345}}>
         <CssBaseline />
         <CardActionArea onClick={props.onClick}>
            <CardMedia
               component="img"
               image={myImage}
               alt="Создать новую доску"
               sx={{
                  width: '100%', // Установите нужную ширину
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}
            />
            <CardContent>
               <Grid
                  container
                  sx={{
                     marginTop: 2,
                  }}>
                  <Grid size={11}>
                     <Typography
                        gutterBottom
                        variant="h4"
                        sx={{
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           textAlign: 'center',
                        }}>
                        Создать новую доску
                     </Typography>
                  </Grid>
               </Grid>
            </CardContent>
         </CardActionArea>
      </Card>
   )
}
