import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useRoutes } from './routes'
import { useAuth } from './hooks/auth.hook'
import { AuthContext } from './context/AuthContext'
import Navbar from './components/Navbar'
import { AlertProvider } from './hooks/AlertContext'

function App() {
   const { token, login, logout, userId } = useAuth() //Устанавливаем значения контекста
   const isAuthenticated = !!token
   const routes = useRoutes(isAuthenticated)
   return (
      <AuthContext.Provider value={{ token, login, logout, userId, isAuthenticated }}>
         <AlertProvider>
            <Router>
               {/* {isAuthenticated && <Navbar />} */}
               <div>{routes}</div>
            </Router>
         </AlertProvider>
      </AuthContext.Provider>
   )
}

export default App
