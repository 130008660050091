import React, { useState } from 'react'
import { useMessage } from '../hooks/AlertContext.js'
import $api from '../http'
import useTitle from '../hooks/title.hook'
import Cookies from 'js-cookie'
import myImage from '../logoNavbar.png'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import AppTheme from '../shared-theme/AppTheme'
import ImageCard from '../components/ImageCard2.js'
function Copyright(props) {
   return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
         {'Copyright © '}
         <Link color="inherit" href="#">
            InterBoard
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   )
}

export const ForgotPasswordPage = (props) => {
   useTitle('Смена пароля - InterBoard')
   const message = useMessage()
   const [form, setForm] = useState({ email: '' })
   const changeHandler = (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
   }

   const sendEmailHandler = async () => {
      try {
         console.log(form.email)
         const changePasswordData = (await $api.post('/api/auth/sendEmailForChangingPassword', { ...form })).data
         message(changePasswordData.message)
      } catch (error) {
         message(error.response.data)
      }
   }
   const handleSubmit = (event) => {
      event.preventDefault()
   }
   return (
      <AppTheme {...props}>
         <ImageCard />
         <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}>
               <Typography component="h1" variant="h4">
                  Смена пароля
               </Typography>
               <Typography component="h6" align="center">
                  Для смены пароля у вас должен быть аккаунт с подтвержденной электронной почтой
               </Typography>
               <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid item xs={12}>
                     <TextField
                        InputProps={{
                           style: {
                              paddingLeft: 10,
                              paddingRight: 10,
                           },
                        }}
                        required
                        fullWidth
                        id="email"
                        label="Email адрес"
                        name="email"
                        autoComplete="email"
                        onChange={changeHandler}
                     />
                  </Grid>
                  <Button type="submit" fullWidth variant="contained" color="info" sx={{ mt: 3, mb: 2 }} onClick={sendEmailHandler}>
                     Запросить смену пароля
                  </Button>
                  <Grid container justifyContent="flex-end">
                     <Grid item>
                        <Link href="/login" variant="body2">
                           Вспомнили пароль? Авторизируйтесь
                        </Link>
                     </Grid>
                  </Grid>
               </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
         </Container>
      </AppTheme>
   )
}
