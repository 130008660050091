import React, { useContext, useEffect, useState } from 'react'
import { useMessage } from '../hooks/AlertContext.js'
import { AuthContext } from '../context/AuthContext'
import $api from '../http'
import useTitle from '../hooks/title.hook'
import Cookies from 'js-cookie'
import myImage from '../logoNavbar.png'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import AppTheme from '../shared-theme/AppTheme'
import ImageCard from '../components/ImageCard2.js'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function Copyright(props) {
   return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
         {'Copyright © '}
         <Link color="inherit" href="#">
            InterBoard
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   )
}

export const RegistrationPage = (props) => {
   useTitle('Регистрация - InterBoard')
   const auth = useContext(AuthContext)
   const message = useMessage()
   const [form, setForm] = useState({ email: '', password: '', code: '', password2: '', firstName: '', lastName: '', showPassword: false })
   const changeHandler = (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
   }
   const [showPassword, setShowPassword] = React.useState(false)
   const handleClickShowPassword = () => setShowPassword((show) => !show)

   const handleMouseDownPassword = (event) => {
      event.preventDefault()
   }

   const handleMouseUpPassword = (event) => {
      event.preventDefault()
   }

   const registerHandler = async () => {
      try {
         if (!isFormOK(form)) return
         if (!isPasswordStrong(form.password, form.password2)) return
         const regData = (await $api.post('/api/auth/register', { ...form })).data
         message(regData.message)
         if (regData.email) {
            const loginData = (await $api.post('/api/auth/login', { email: regData.email, password: regData.password })).data
            message(loginData.message)
            if (Cookies.get('accessToken')) auth.login(Cookies.get('accessToken'), loginData.userId, loginData.name, loginData.prefBoardTheme)
         }
      } catch (error) {
         message(error.response.data)
      }
   }
   const handleSubmit = (event) => {
      event.preventDefault()
   }
   function isFormOK(form) {
      const firstName = form.firstName
      const lastName = form.lastName
      const code = form.code
      if (firstName.length < 1 || firstName.length > 30) {
         message('Некорректная длина имени')
         return false
      }
      if (lastName.length < 1 || lastName.length > 30) {
         message('Некорректная длина фамилии')
         return false
      }
      if (code !== 'Шляпик') {
         message('Неправильное кодовое слово')
         return false
      }
      return true
   }
   function isPasswordStrong(password, password2) {
      // Проверяем длину пароля
      if (password !== password2) {
         message('Пароли не совпадают!')
         return false
      }
      if (password.length <= 10) {
         message('В пароле должно быть как минимум 10 символов!')
         return false
      }
      if (password.length > 128) {
         message('Пароль слишком длинный!')
         return false
      }
      // Регулярные выражения для проверки различных типов символов
      const russianLetterPattern = /[а-яА-ЯёЁ]/.test(password)
      const hasUpperCase = /[A-Z]/.test(password)
      const hasLowerCase = /[a-z]/.test(password)
      const hasNumbers = /[0-9]/.test(password)
      // const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password)

      // Пароль считается сильным, если он содержит
      // буквы в верхнем и нижнем регистре, цифры и специальные символы, русские буквы
      if (russianLetterPattern) message('В пароле не должно быть русских букв!')
      else if (!hasUpperCase) message('В пароле должна быть хотя бы одна заглавная буква!')
      else if (!hasLowerCase) message('В пароле должна быть хотя бы одна строчная буква!')
      else if (!hasNumbers) message('В пароле должна быть хотя бы одна цифра!')
      // else if (!hasSpecialChars) message('В пароле должен быть хотя бы один специальный символ, например !@#$%^&*(),.?":{}|<>')

      if (hasUpperCase && hasLowerCase && hasNumbers && !russianLetterPattern) return true

      return false
   }
   return (
      <AppTheme {...props}>
         <CssBaseline />
         <ImageCard />
         <Container component="main" maxWidth="xs">
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}>
               <Typography component="h1" variant="h4">
                  Регистрация
               </Typography>
               <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           autoComplete="given-name"
                           name="firstName"
                           required
                           fullWidth
                           id="firstName"
                           label="Имя"
                           autoFocus
                           onChange={changeHandler}
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           required
                           fullWidth
                           id="lastName"
                           label="Фамилия"
                           name="lastName"
                           autoComplete="family-name"
                           onChange={changeHandler}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           required
                           fullWidth
                           id="email"
                           label="Email адрес"
                           name="email"
                           autoComplete="email"
                           onChange={changeHandler}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth required>
                           <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                           <OutlinedInput
                              sx={{
                                 paddingLeft: '10px', // Задайте нужное значение отступа
                              }}
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              id="password"
                              autoComplete="new-password"
                              onChange={changeHandler}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       aria-label={showPassword ? 'hide the password' : 'display the password'}
                                       onClick={handleClickShowPassword}
                                       onMouseDown={handleMouseDownPassword}
                                       onMouseUp={handleMouseUpPassword}
                                       edge="end">
                                       {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                 </InputAdornment>
                              }
                              label="пароль"
                           />
                        </FormControl>
                     </Grid>
                     <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth required>
                           <InputLabel htmlFor="outlined-adornment-password">Повторите пароль</InputLabel>
                           <OutlinedInput
                              sx={{
                                 paddingLeft: '10px', // Задайте нужное значение отступа
                              }}
                              name="password2"
                              type={showPassword ? 'text' : 'password'}
                              id="password2"
                              autoComplete="new-password"
                              onChange={changeHandler}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       aria-label={showPassword ? 'hide the password' : 'display the password'}
                                       onClick={handleClickShowPassword}
                                       onMouseDown={handleMouseDownPassword}
                                       onMouseUp={handleMouseUpPassword}
                                       edge="end">
                                       {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                 </InputAdornment>
                              }
                              label="пароль"
                           />
                        </FormControl>
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           autoComplete="given-name"
                           name="code"
                           required
                           fullWidth
                           id="code"
                           label="Кодовое слово"
                           onChange={changeHandler}
                        />
                     </Grid>
                     {/* <Grid item xs={12}>
                        <FormControlLabel
                           control={<Checkbox value="allowExtraEmails" color="primary" />}
                           label="I want to receive inspiration, marketing promotions and updates via email."
                        />
                     </Grid> */}
                  </Grid>
                  <Button type="submit" fullWidth variant="contained" color="info" sx={{ mt: 3, mb: 2 }} onClick={registerHandler}>
                     Зарегистрироваться
                  </Button>
                  <Grid container justifyContent="flex-end">
                     <Grid item>
                        <Link href="/login" variant="body2">
                           Уже есть аккаунт? Авторизируйтесь
                        </Link>
                     </Grid>
                  </Grid>
               </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
         </Container>
      </AppTheme>
   )
}
