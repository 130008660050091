import React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import myImage from '../logoNavbar.png'
import Container from '@mui/material/Container'
import { CardActionArea } from '@mui/material'

function ImageCard() {
   return (
      <Container maxWidth="md">
         <Card sx={{ boxShadow: 'none', border: 'none' }}>
            <CardActionArea href="/">
               <CardMedia component="img" image={myImage} alt="Description of the image" />
            </CardActionArea>
         </Card>
      </Container>
   )
}

export default ImageCard
